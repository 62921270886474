.container {
    top: 0;
    background: var(--bgColor);
    display: flex;
    flex-direction: column;
    margin-top: 0px; /* Ensure the container has a margin from the fixed searchContainer */
}

.searchContainer {
    top: 0; /* Fix to the top of the screen */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    height: auto;
    z-index: 101;
    background-color: var(--bgColor); /* Ensure it is not transparent */
}

.search {
    font-size: var(--row-height);
    background-color: var(--bgColor);
    height: auto; /* Set a fixed height */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1px;
    padding: 5px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.search input {
    flex: 1;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    border: 1px solid var(--tg-theme-secondary-bg-color);
    height: 100%; /* Match the height of the parent */
}

.searchButton {
    background-color: var(--bgColor);
    border: none;
    font-size: var(--row-height);
    height: 40px; /* Match the height of the search container */
    width: 40px; /* Square button */
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchHint {
    font-size: 0.5rem;
    display: flex;
    flex-direction: column;
}

.searchResultBlock{
    display: flex;
    flex-direction: column;
    /* background-color: var(--tg-theme-secondary-bg-color); */
    border-radius: 5px;
    margin-top: 7px;
    padding: auto;
}

.closeSearchResult {
    background-color: var(--bgColor);
    position: static;
    font-size: 0.7rem;
    width: fit-content;
    align-self: flex-end;
    bottom: 10px;
    border: none; /* Remove borders */
}

.searchResultItem{
    font-size: 0.7rem;
    
}

.serachResult{
    display: flex;
    flex-direction: column;
    background-color: var(--tg-theme-secondary-bg-color);
    border-radius: 5px;
    margin-top: 7px;
}

.date {
    text-align: center;
    margin: auto;
}

.caBlock {
    background-color: var(--tg-theme-secondary-bg-color);
    margin-bottom: 5px;
    flex-direction: row;
    border-radius: 5px;
    margin-top: 5px; /* Ensure it has a margin from the fixed searchContainer */
}

.ca {
    margin-top: 10px;
    text-align: center;
}

.manager {
    font-size: 0.5rem;
    margin-left: 2px;
}

.nomenclatureBlock {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    margin-left: 2px;
    margin-right: 2px;
    height: fit-content;
    font-size: 1rem;
    opacity: 1;
    visibility: visible;
}

.nomenclatureRow {
    font-size: 0.7rem;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid;
    --row-height: 100%;
    height: var(--row-height);
}

.no {
    text-align: center;
    width: 1rem;
    margin: auto;
}

.amt {
    flex: 1;
    width: 2rem;
    text-align: center;
    margin: auto;
}

.nomenclature {
    border-left: 1px solid;
    border-right: 1px solid;
    width: calc(100% - 3rem);
}

.nomenclatureBlock.hide {
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.expand {
    display: flex;
    position: relative;
    font-size: 0.7rem;
    color: blue;
    text-decoration: underline;
    justify-content: right;
    margin-top: 3px;
    z-index: 1000;
    overflow: hidden;
    outline: 2px solid var(--tg-theme-secondary-bg-color);
}

.progress {
    display: flex;
    justify-content: center;
    margin-top: 45vh;
}
